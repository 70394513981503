import React from "react";
import "styles/pages/page-business.scss";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "../components/SubpageHeader";
import TitleWithLine from "../components/TitleWithLine";
import SectionHeading from "components/SectionHeading";
import Accordion from "../components/Accordion";
import TextSlider from "../components/TextSlider";

const BusinessInsights = ({ pageContext }) => {
  const bi = pageContext.pageContent;

  const accordionItems = bi.sectionColWmudTexts.map((item, index) => {
    return {
      key: index,
      desc: item.sectionColWmudText,
    };
  });

  const accordionItemsWork = bi.sectionColHdwwTexts.map((item, index) => {
    return {
      key: index,
      desc: item.sectionColHdwwText,
    };
  });

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/business_bg.jpg").default}
      />
      <section className="section-benefits">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-benefits__inner">
                <div
                  className="section-benefits__text"
                  dangerouslySetInnerHTML={{ __html: bi.sectionBiIntroText }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="section-benefits__img-wrapper">
                <img
                  src={bi.sectionBiIntroImage?.sourceUrl}
                  alt=""
                  className="img-fluid section-benefits__img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-why">
        <div className="container">
          <SectionHeading
            className="section-why__title"
            title={bi.sectionWdynTitle}
            subtitle={bi.sectionWdynText}
          />
          <img
            src={bi.sectionWdynImage?.sourceUrl}
            alt=""
            className="img-fluid section-why__img"
          />
        </div>
      </section>

      <section className="section-integration">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-integration__img-wrapper">
                <img
                  src={bi.biParagraph1Image?.sourceUrl}
                  alt=""
                  className="img-fluid section-integration__img"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="section-integration__inner"
                dangerouslySetInnerHTML={{ __html: bi.biParagraph1Text }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-operations">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <TitleWithLine variantY="bottom">
                {bi.biParagraph2Title}
              </TitleWithLine>
              <div className="section-operations__inner">
                <TextSlider texts={bi.biParagraph2Text} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-operations__img-wrapper">
                <img
                  src={bi.biParagraph2Image?.sourceUrl}
                  alt=""
                  className="img-fluid section-operations__img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-transactions">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-transactions__img-wrapper">
                <img
                  src={bi.biParagraph3Image?.sourceUrl}
                  alt=""
                  className="img-fluid section-transactions__img"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="section-transactions__inner"
                dangerouslySetInnerHTML={{ __html: bi.biParagraph3Text }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-value">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-value__inner">
                <TitleWithLine variantY="bottom">
                  {bi.biParagraph4Title}
                </TitleWithLine>
                <div
                  dangerouslySetInnerHTML={{ __html: bi.biParagraph4Text }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="section-value__img-wrapper">
                <img
                  src={bi.biParagraph4Image?.sourceUrl}
                  alt=""
                  className="img-fluid section-value__img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-stages">
        <div className="container">
          <SectionHeading
            title={bi.sectionBisTitle}
            subtitle={bi.sectionBisSubtitle}
          ></SectionHeading>
          <div className="section-stages__wrapper">
            <img
              src={require("assets/images/businnes_graph.png").default}
              alt=""
              className="img-fluid section-stages__img"
            />
            <img
              src={require("assets/images/businnes_graph_rotated.png").default}
              alt=""
              className="img-fluid section-stages__img section-stages__img--mobile"
            />
            {bi.sectionBisCaptions.map((item, index) => {
              return (
                <div className="section-stages__text" key={index}>
                  {item.sectionBisCaption}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="section-warehouse">
        <SectionHeading
          className="section-warehouse__title"
          title={bi.sectionDwhTitle}
          subtitle={bi.sectionDwhSubtitle}
          margin=""
        ></SectionHeading>
        <div className="section-warehouse__tables">
          <div className="container">
            <div className="row">
              {bi.sectionDwhColumns.map((item, index) => {
                return (
                  <div
                    className="col-lg-4 section-warehouse__col-1"
                    key={index}
                  >
                    <div className="section-warehouse__inner">
                      <TitleWithLine variantY="bottom">
                        {item.sectionDwhColumnTitle}
                      </TitleWithLine>
                      <div
                        className="section-warehouse__text"
                        dangerouslySetInnerHTML={{
                          __html: item.sectionDwhColumnText,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="section-warehouse__info"
          dangerouslySetInnerHTML={{ __html: bi.sectionDwhParagraph }}
        />
      </section>
      <section className="section-reports">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-reports__img-wrapper">
                <img
                  src={bi.biParagraph5Image?.sourceUrl}
                  alt=""
                  className="img-fluid section-reports__img"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="section-reports__inner">
                <TitleWithLine variantY="bottom">
                  {bi.biParagraph5Title}
                </TitleWithLine>
                <div
                  className="section-reports__text"
                  dangerouslySetInnerHTML={{ __html: bi.biParagraph5Text }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-plan">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 offset-lg-1">
              <div className="section-plan__inner">
                <h3 className="section-plan__title">
                  {bi.sectionColWmudTitle}
                </h3>
              </div>
              <Accordion
                className="accord"
                visibleOnStart={true}
                items={accordionItems}
              />
            </div>
            <div className="col-lg-4 offset-lg-2">
              <div className="section-plan__inner">
                <h3 className="section-plan__title">
                  {bi.sectionColHdwwTitle}
                </h3>
              </div>
              <Accordion
                className="accord-x"
                visibleOnStart={true}
                items={accordionItemsWork}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BusinessInsights;
