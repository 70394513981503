import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import theme from "theme";

const TextSliderWrapper = styled.div`
  margin-top: -60px;

  .slick-slide {
    padding: 60px 0;
  }

  .slick-dots {
    bottom: -50px;
    left: 5%;

    ${theme.screens.lgScreen} {
      left: 2%;
      bottom: 0;
    }

    ${theme.screens.mdScreen} {
      left: 5%;
      bottom: 0;
    }

    ${theme.screens.smScreen} {
      left: 10%;
      bottom: 0;
    }
  }
`;

const SingleSlide = styled.div`
  background-color: #fff;
  border-bottom: 3px solid transparent;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  ${theme.shadows.shadow}

  ${theme.mixins.onEvent} {
    border-bottom: 3px solid #ca3b6c;
  }
  ${theme.screens.mdScreen} {
    margin: 0;
  }
`;

const Text = styled.div`
  text-align: left;
  font-display: 16px;
  margin-bottom: 15px;
  overflow: auto;
`;

const TextSlider = ({ texts }) => {
  const textSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <TextSliderWrapper>
      <Slider {...textSlider}>
        {texts.map((text, index) => {
          return (
            <div key={index}>
              <SingleSlide>
                <Text>{text.text}</Text>
              </SingleSlide>
            </div>
          );
        })}
      </Slider>
    </TextSliderWrapper>
  );
};

export default TextSlider;
